import { Button, Checkbox, Divider, Input, Radio, message } from "antd";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/main-logo.png";
import { Center } from "../../components/style-component/layout.style-component";
import { ROUTE_PATH } from "../../constants/path.route";
import { useAuthentication } from "../../hooks/useAuth";
import { userLoginWithUsernameAndPassword } from "../../services/auth.service";
import i18n, { setLangToLocalStorage } from "../../locales/i18n.main";

const LoginPage = () => {
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng") || "km");
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("remember_me") || "false"
  );
  const [playing, setPlaying] = useState(false);

  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { saveUser } = useAuthentication();

  // const handleVideoClick = (control) => {
  //   if (control === "play") {
  //     (videoRef as any).current.play();
  //     setPlaying(true);
  //   } else if (control === "pause") {
  //     (videoRef as any).current.pause();
  //     setPlaying(false);
  //   }
  // };

  const videoLoginScreen = (
    <div className="flex h-screen">
      <div
        style={{ backgroundColor: "#D0EBF2" }}
        className="flex w-full items-center justify-center p-8"
      >
        <iframe
          width="980"
          // this src will show no suggestions of other peoples videos and also allow loop
          src="https://www.youtube.com/embed/_yboNVTGBWk?playlist=_yboNVTGBWk&loop=1&rel=0"
          title="YouTube video player"
          allow=" autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          // allowFullScreen
          className="rounded-xl aspect-video"
        ></iframe>
      </div>
    </div>
  );

  const onSubmit = (value) => {
    if (!!value.username && !!value.password) {
      setLoading(true);
      userLoginWithUsernameAndPassword(value)
        .then((res) => {
          setLoading(false);
          const { token } = res.data;
          const { refresh_token } = res.data;
          saveUser({ token: token, refresh_token: refresh_token });
          localStorage.setItem("remember_me", rememberMe);
          navigate(ROUTE_PATH.SMART_LIBRARY);
        })
        .catch((e) => {
          if (e.response.data.statusCode === 400) {
            message.error(
              t("Your account was logged in and used on another device.")
            );
            setLoading(false);
            return;
          }

          if (e.response.data.statusCode === 401) {
            message.error(t("That Kru Chlat account deos not exist."));
            setLoading(false);
            return;
          }

          message.error(e.message);
          setLoading(false);
        });
    } else {
      message.warn(t("Please complete all the required fields."));
    }
  };

  const LoginLayout = (
    <div className="flex h-screen">
      <div
        style={{ backgroundColor: "#D0EBF2" }}
        className="flex w-full items-center justify-center p-8"
      >
        {/* {!playing && (
          <div
            // onClick={() => {
            //   handleVideoClick("play");
            // }}
            className="absolute z-10 flex items-center justify-center rounded-full bg-black bg-opacity-30"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-24 w-24 cursor-pointer text-white opacity-75"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M8 5v14l11-7z"
              />
            </svg>
          </div>
        )} */}
        {/* <video
          onClick={() => {
            if (!playing) {
              handleVideoClick("play");
            } else {
              handleVideoClick("pause");
            }
          }}
          ref={videoRef}
          className="relative h-full cursor-pointer rounded-xl object-cover"
          controls={false}
          loop
        >
          <source src="../../assets/intro-vdo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        {videoLoginScreen}
      </div>
      <div style={{ backgroundColor: "#FFFFFF" }} className="h-full w-500">
        <Center className="">
          <form>
            <div
              style={{ backgroundColor: "#FFFFFFF" }}
              className="rounded-xl px-12 py-5"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <div
                  style={{ paddingBottom: 20 }}
                  className="flex flex-col space-y-1"
                >
                  <img src={logo} width={120} alt={"logo"} />
                  <Divider></Divider>
                  <p className="text-[14px] text-primaryColor">
                    {t("Created by teacher for teacher")}
                  </p>
                </div>
              </div>

              <div>
                <p style={{ marginBottom: 5, fontSize: 16 }}>
                  {t("Welcome back teacher,")}
                </p>
                <p className="text-gray" style={{ fontSize: 14 }}>
                  {t("Login to continue")}
                </p>
              </div>

              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      style={{ padding: 10, borderRadius: 12 }}
                      placeholder={t("Username")}
                      name={t("Username")}
                      // prefix={<UserOutlined />}
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input.Password
                      placeholder={t("Password")}
                      style={{ marginTop: 10, padding: 10, borderRadius: 12 }}
                      name={t("Password")}
                      // prefix={<LockOutlined />}
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />

              {/* <div className="pt-4 select-none">
                <Checkbox onChange={(e) => {
                  const rememberMe = e.target.checked;
                  setRememberMe(JSON.stringify(rememberMe));
                }}
                  defaultChecked={JSON.parse(rememberMe)}
                >{t("Keep me logged in")}</Checkbox>
              </div> */}

              <div className="flex justify-center pt-5 ">
                <div style={{ width: "100%" }}>
                  <Button
                    style={{ width: "100%", height: 40, borderRadius: 12 }}
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button text-lg"
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("Login")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="mt-auto flex justify-center space-x-1">
              <p style={{ marginBottom: 5, fontSize: 12 }}>
                {t("Forgot your password?")}
              </p>
              <p
                onClick={() => navigate("/reset-password")}
                className="cursor-pointer select-none text-primaryColor underline"
                style={{ fontSize: 12, color: "#1199E9" }}
              >
                {t("Click here")}
              </p>
            </div>
            <div className="mt-auto flex justify-center space-x-1">
              <p style={{ marginBottom: 5, fontSize: 12 }}>
                {t("Did not have an account yet?")}
              </p>
              <p
                onClick={() => navigate(ROUTE_PATH.REGISTER)}
                className="cursor-pointer select-none text-primaryColor underline"
                style={{ fontSize: 12, color: "#1199E9" }}
              >
                {t("Click here")}
              </p>
            </div>

            {/* Disable Language selection for now */}
            {/* <div className="flex mt-auto justify-center">
              <Radio.Group
                onChange={(e) => {
                  const lang = e.target.value;
                  setLang(lang);
                  setLangToLocalStorage(lang);
                  i18n.changeLanguage(lang);
                }}
                defaultValue={lang}
              >
                <Radio value={'km'}>🇰🇭 Khmer</Radio>
                <Radio value={'en'}>🇺🇸 English</Radio>
              </Radio.Group>
            </div> */}

            <div className="mt-[1rem] flex justify-center">
              <Radio.Group
                onChange={(e) => {
                  const lang = e.target.value;
                  setLangToLocalStorage(lang);
                  i18n.changeLanguage(lang);
                }}
                defaultValue={"km"}
              >
                <Radio value={"km"}>🇰🇭 Khmer</Radio>
                <Radio value={"en"}>🇺🇸 English</Radio>
              </Radio.Group>
            </div>
          </form>
        </Center>
      </div>
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      {LoginLayout}
      <div id={"recaptcha-container"}></div>
    </div>
  );
};

export default LoginPage;
