import jwtDecode from 'jwt-decode';
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { REFRESH_TOKEN_KEY, REMEMBER_ME_KEY, TOKEN_KEY } from '../constants/app.constant';
import { ROUTE_PATH } from '../constants/path.route';
import { AuthResponse, UserContextType } from '../types/common';
import { ReactChildren } from '../types/react-children';

export const UserContext = createContext<UserContextType>({
  token: undefined,
  refreshToken: undefined,
  role: undefined,
  logoutUser: () => {
    //
  },
  saveUser: (data) => {
    //
  },
  setRoleByToken: () => {
    //
  },
});

const UserProvider: React.FC<{
  children: ReactChildren;
}> = (props): JSX.Element => {
  const savedToken: any = localStorage.getItem(TOKEN_KEY);
  const saveRefreshToken: any = localStorage.getItem(REFRESH_TOKEN_KEY);
  const [token, setToken] = useState<string | undefined>(savedToken);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(saveRefreshToken);
  const [role, setRole] = useState<string | undefined>(undefined);
  const route = useNavigate();

  const saveUser = (data: AuthResponse) => {
    localStorage.setItem(TOKEN_KEY, data.token);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
    setToken(data.token);
    setRefreshToken(data.refresh_token);
  };
  const logoutUser = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REMEMBER_ME_KEY);

    setToken(undefined);
    setRefreshToken(undefined);
    route(ROUTE_PATH.LOGIN);
  };

  const setRoleByToken = () => {
    if (token) {
      const decodedToken: { role: string; id: string; email: string } =
        jwtDecode(token);
      setRole(decodedToken.role);
    }
  };

  return (
    <UserContext.Provider
      value={{ token, refreshToken, saveUser, logoutUser, setRoleByToken, role }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserProvider };
