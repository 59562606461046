import { Input, Button, Radio, Divider } from "antd";
import logo from "../../assets/main-logo.png";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n, { setLangToLocalStorage } from "../../locales/i18n.main";
import { Center } from "../../components/style-component/layout.style-component";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/path.route";
import { useRef, useState } from "react";
import RegisterComponent from "./components/register-component";

const RegisterPage = () => {
  const videoRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [playing, setPlaying] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleVideoClick = (control) => {
    if (control === "play") {
      (videoRef as any).current.play();
      setPlaying(true);
    } else if (control === "pause") {
      (videoRef as any).current.pause();
      setPlaying(false);
    }
  };

  const RegisterLayout = (
    <div style={{ backgroundColor: "#FFFFFF" }} className="h-full w-full">
      <Center className="">
        <div>
          <div
            style={{ backgroundColor: "#FFFFFFF" }}
            className="rounded-xl px-12 py-5"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                style={{ paddingBottom: 20 }}
                className="flex flex-col space-y-1"
              >
                <img src={logo} width={120} alt={"logo"} />
                <Divider></Divider>

                <p className="text-[14px] text-primaryColor">
                  {t("Created by teacher for teacher")}
                </p>
              </div>
            </div>

            <RegisterComponent />
            {/* 
            <div className="flex flex-col ">
              <div className="flex gap-5  ">
                <label>
                  Firstname
                  <input
                    placeholder="Firstname"
                    className=" ml-[5px] rounded-md border border-primaryColor p-2"
                  />
                </label>

                <label>
                  Lastname
                  <input
                    placeholder="Lastname"
                    className=" ml-[5px] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>

              <div>
                <label className="flex items-center justify-center gap-[1px]">
                  Username
                  <input
                    placeholder="Username"
                    className=" ml-[5px] w-[90%] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>
              <div>
                <label className="w-[10%[ flex items-center justify-center gap-[1px]">
                  Email
                  <input
                    placeholder="Email"
                    className=" ml-[5px] w-[90%] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>

              <div>
                <label className="w-[10%[ flex items-center justify-center gap-[1px]">
                  Password
                  <input
                    placeholder="Password"
                    className=" ml-[5px] w-[90%] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>
              <div>
                <label className="w-[10%[ flex items-center justify-center gap-[1px]">
                  Phone Number
                  <input
                    placeholder="Password"
                    className=" ml-[5px] w-[90%] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>
              <div>
                <label className="w-[10%[ flex items-center justify-center gap-[1px]">
                  Address
                  <input
                    placeholder="Password"
                    className=" ml-[5px] w-[90%] rounded-md border border-primaryColor p-2"
                  />
                </label>
              </div>
            </div> */}

            {/* <div>
              <p style={{ marginBottom: 5, fontSize: 16 }}>
                {t("Request create an account")}
              </p>
              <p style={{ fontSize: 14, color: "#414141" }}>
                {t(
                  "Fill in your information in the request form or contact to Kru Chlat team"
                )}
              </p>
            </div> */}

            <div className="flex justify-center  ">
              <div style={{ width: "100%" }}>
                {/* <Button
                  onClick={() =>
                    openInNewTab("https://forms.gle/mQQgXNVraqoeRSgF9")
                  }
                  style={{ width: "100%", height: 40, borderRadius: 12 }}
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button "
                > */}
                {/* {t("Fill in the form")} */}
                {/* {t("Create an account")}
                </Button> */}
              </div>
            </div>
            <Divider dashed></Divider>
            <div className="flex justify-center ">
              <div style={{ width: "100%" }}>
                <Button
                  onClick={() => openInNewTab("https://t.me/danychhe")}
                  style={{ width: "100%", height: 40, borderRadius: 12 }}
                  type="ghost"
                  htmlType="submit"
                  block
                  className="login-form-button "
                >
                  {t("Contact Kru Chlat Team")}
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-auto flex justify-center space-x-1">
            <p style={{ marginBottom: 5, fontSize: 12 }}>
              {t("Already have an account?")}
            </p>
            <p
              onClick={() => navigate(ROUTE_PATH.LOGIN)}
              className="cursor-pointer select-none underline"
              style={{ fontSize: 12, color: "#1199E9" }}
            >
              {t("Click here")}
            </p>
          </div>

          <div className="mt-auto mb-4 flex justify-center">
            <Radio.Group
              onChange={(e) => {
                const lang = e.target.value;
                setLangToLocalStorage(lang);
                i18n.changeLanguage(lang);
              }}
              defaultValue={"km"}
            >
              <Radio value={"km"}>🇰🇭 Khmer</Radio>
              <Radio value={"en"}>🇺🇸 English</Radio>
            </Radio.Group>
          </div>
        </div>
      </Center>
    </div>
  );
  // <div className="flex h-screen">

  /* <div style={{ backgroundColor: '#D0EBF2' }} className="w-full flex justify-center items-center p-8">
                {!playing && (
                    <div onClick={() => {
                        handleVideoClick('play')
                    }} className="flex justify-center items-center absolute z-10 bg-black bg-opacity-30 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 text-white opacity-75 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 5v14l11-7z" />
                        </svg>
                    </div>
                )}
                <video onClick={() => {
                    if (!playing) {
                        handleVideoClick('play')
                    } else {
                        handleVideoClick('pause')
                    }
                }} ref={videoRef} className="h-full object-cover relative rounded-xl cursor-pointer" controls={false} loop>
                    <source src="../../assets/intro-vdo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div> */

  //       <div style={{ backgroundColor: "#FFFFFF" }} className="h-full w-500">
  //         <Center className="">
  //           <div>
  //             <div
  //               style={{ backgroundColor: "#FFFFFFF" }}
  //               className="rounded-xl px-12 py-5"
  //             >
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   justifyContent: "left",
  //                 }}
  //               >
  //                 <div
  //                   style={{ paddingBottom: 20 }}
  //                   className="flex flex-col space-y-1"
  //                 >
  //                   <img src={logo} width={120} alt={"logo"} />
  //                   <Divider></Divider>
  //                   <p className="text-[14px] text-primaryColor">
  //                     {t("Created by teacher for teacher")}
  //                   </p>
  //                 </div>
  //               </div>

  //               <div>
  //                 <p style={{ marginBottom: 5, fontSize: 16 }}>
  //                   {t("Request create an account")}
  //                 </p>
  //                 <p style={{ fontSize: 14, color: "#414141" }}>
  //                   {t(
  //                     "Fill in your information in the request form or contact to Kru Chlat team"
  //                   )}
  //                 </p>
  //               </div>

  //               <div className="flex justify-center pt-5 ">
  //                 <div style={{ width: "100%" }}>
  //                   <Button
  //                     onClick={() =>
  //                       openInNewTab("https://forms.gle/mQQgXNVraqoeRSgF9")
  //                     }
  //                     style={{ width: "100%", height: 40, borderRadius: 12 }}
  //                     type="primary"
  //                     htmlType="submit"
  //                     block
  //                     className="login-form-button "
  //                   >
  //                     {t("Fill in the form")}
  //                   </Button>
  //                 </div>
  //               </div>
  //               <Divider dashed></Divider>
  //               <div className="flex justify-center ">
  //                 <div style={{ width: "100%" }}>
  //                   <Button
  //                     onClick={() => openInNewTab("https://t.me/danychhe")}
  //                     style={{ width: "100%", height: 40, borderRadius: 12 }}
  //                     type="ghost"
  //                     htmlType="submit"
  //                     block
  //                     className="login-form-button "
  //                   >
  //                     {t("Contact Kru Chlat Team")}
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="mt-auto flex justify-center space-x-1">
  //               <p style={{ marginBottom: 5, fontSize: 12 }}>
  //                 {t("Already have an account?")}
  //               </p>
  //               <p
  //                 onClick={() => navigate(ROUTE_PATH.LOGIN)}
  //                 className="cursor-pointer select-none underline"
  //                 style={{ fontSize: 12, color: "#1199E9" }}
  //               >
  //                 {t("Click here")}
  //               </p>
  //             </div>

  //             <div className="mt-auto flex justify-center">
  //               <Radio.Group
  //                 onChange={(e) => {
  //                   const lang = e.target.value;
  //                   setLangToLocalStorage(lang);
  //                   i18n.changeLanguage(lang);
  //                 }}
  //                 defaultValue={"km"}
  //               >
  //                 <Radio value={"km"}>🇰🇭 Khmer</Radio>
  //                 <Radio value={"en"}>🇺🇸 English</Radio>
  //               </Radio.Group>
  //             </div>
  //           </div>
  //         </Center>
  //   </div>

  return (
    <div style={{ position: "relative" }}>
      {RegisterLayout}
      <div id={"recaptcha-container"}></div>
    </div>
  );
};

export default RegisterPage;
