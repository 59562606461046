import { QueryClient } from 'react-query';
import { AxiosHttp } from './axios.config';

const defaultQueryFn = async (props: any) => {
  const url = props.queryKey;
  const data = await AxiosHttp.get(`${url}`);
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: true,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    },
  },
});
