import { SuraMultiCountryPhoneInputProvider } from '@asurraa/multi-country-phone-input';
import { SuraFetcherProvider } from '@asurraa/sura-ui-fetcher';
import { AsurRaaImageViewerProvider } from '@asurraa/sura-ui-image-viewer';
import { AsurRaaDraggableModalProvider } from '@asurraa/sura-ui-modal';
import { AsurRaaSelectBaseApiProvider } from '@asurraa/sura-ui-select-search';
import { AsurRaaTableProvider } from '@asurraa/sura-ui-table';
import { AsurRaaUploadProvider } from '@asurraa/sura-ui-upload';
import { dateAsurRaaFormatOnlyDate } from '@asurraa/sura-ui-utilities';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale/en_US';
import { QueryClientProvider, useQuery } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AxiosHttp } from '../config/axios.config';
import { queryClient } from '../config/react-query.config';

import {
  API_BASE_URL,
  fallBackImage,
  returnImageRoute,
} from '../constants/app.constant';
import { URI_PATH } from '../constants/uri-path.constant';
import { UserProvider } from '../context/user.context';
import { UploadResponse } from '../interface';
import { ISuraPagination } from '../types/common';
import StoreProviderWrapper from './StoreProviderWrapper';

const AppProviderWrapper = () => {
  return (
    <ConfigProvider locale={en_US}>
      <BrowserRouter>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <AsurRaaDraggableModalProvider modalType="dnd">
              <AsurRaaTableProvider formateDate={dateAsurRaaFormatOnlyDate}>
                <AsurRaaUploadProvider<UploadResponse>
                  returnImagePath={returnImageRoute}
                  postUrl={`${API_BASE_URL}${URI_PATH.UPLOAD}`}
                  parseRes={(res) => res?.['data']}
                >
                  <AsurRaaImageViewerProvider
                    allowPreview={true}
                    fallbackType="image"
                    imageUrl={returnImageRoute}
                    fallbackImage={fallBackImage}
                    advanced={{ crossOrigin: 'anonymous', headers: {} }}
                  >
                    <SuraFetcherProvider
                      singleParamInterceptor={{ data: (res) => res?.data }}
                      axiosInstance={AxiosHttp}
                      useQueryInstance={useQuery}
                      paramInterceptor={{
                        data: (res) => res?.data,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        meta: (res) => res?.meta,
                      }}
                    >
                      <AsurRaaSelectBaseApiProvider<ISuraPagination>
                        fetcher={AxiosHttp}
                        uri={{ page: 'page' }}
                        parseSearch={(searchValue, key) =>
                          `search[${key}]=${searchValue}`
                        }
                        parseResponse={{
                          data: (res) => res?.data,
                          meta: (res) => res?.meta,
                        }}
                        metaTotalPage={(meta) => meta.totalPages}
                      >
                        <SuraMultiCountryPhoneInputProvider
                          defaultCountry="KH"
                          scopeCountry={['KH']}
                        >
                          <StoreProviderWrapper />
                        </SuraMultiCountryPhoneInputProvider>
                      </AsurRaaSelectBaseApiProvider>
                    </SuraFetcherProvider>
                  </AsurRaaImageViewerProvider>
                </AsurRaaUploadProvider>
              </AsurRaaTableProvider>
            </AsurRaaDraggableModalProvider>
          </QueryClientProvider>
        </UserProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default AppProviderWrapper;
