import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { API_BASE_URL, TOKEN_KEY } from '../constants/app.constant';
import { refreshAdminToken } from '../services/auth.service';
import { ErrorResponseInterface } from '../types/common';

const axiosAuthConfiguration: AxiosRequestConfig = {
  baseURL: API_BASE_URL,
  timeout: 25000,
};

const AxiosHttp = Axios.create(axiosAuthConfiguration);

AxiosHttp?.interceptors?.request?.use(async (config: AxiosRequestConfig) => {
  let token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const decoded: any = jwtDecode(token);
    const now = new Date().getTime() / 1000;

    if (decoded?.exp < now) {
      await refreshAdminToken();
      token = localStorage.getItem(TOKEN_KEY);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    config.headers['authorization'] = `Bearer ${token}`;
  }

  return config;
});

AxiosHttp?.interceptors?.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response?.data);
  }
  // (error: AxiosError<ErrorResponseInterface>) => {
  //   if (!error.response) {
  //     return Promise.reject({
  //       message: 'Unable to connect to server',
  //       statusCode: 400,
  //     } as ErrorResponseInterface);
  //   }
  //   return Promise.reject({
  //     message: error.response?.data?.message,
  //     statusCode: error.response?.status,
  //   } as ErrorResponseInterface);
  // }
);

export { AxiosHttp, axiosAuthConfiguration };
