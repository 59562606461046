import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryColor } from '../../constants/theme.constant';
import { Button, Input, message } from 'antd';
import { Iconly } from 'react-iconly';
import { Controller, useForm } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import { sendMessageToGroup } from '../../services/telegram-bot.service';
import { useUserInfoService } from '../../services/user.service';

const SubscriptionExpiredPage = () => {
  const { t } = useTranslation();
  const { data } = useUserInfoService();
  const { handleSubmit, control, reset } = useForm();

  const sendMessageFromBot = (text, topic_id) => {
    sendMessageToGroup(text, topic_id).then(() => {
      message.success(t("You have successfully logged out!"));
      reset();
    }).catch((e) => {
      message.error(e);
    });
  }

  const onSubmit = (value) => {
    if (!!value.text) {
      const topicID = '3';
      const msg = `<b>❗️ មិនបានបន្តការភ្ជាប់គម្រោងទេ</b>\n-------------------------------------\n\nព័ត៌មានអ្នកប្រើប្រាស់៖\nឈ្មោះពេញ៖ <b>${data?.firstname} ${data?.lastname}</b>\nលេខទូរសព្ទ៖ <b>${data?.phone_number}</b>\n\nមូលហេតុ ឬយោបល់៖ <b>${value.text}</b>`;
      sendMessageFromBot(msg, topicID);
    } else {
      message.warn(t("Please complete all the required fields."));
    }
  }

  const onRequestSubscribe = () => {
    const topicID = '7';
    const msg = `<b>✅ ស្នើសុំការការភ្ជាប់គម្រោង</b>\nអ្នកប្រើប្រាស់នេះបានស្នើសុំការភ្ជាប់គម្រោង\n-------------------------------------\n\nព័ត៌មានអ្នកប្រើប្រាស់៖\nឈ្មោះពេញ៖ <b>${data?.firstname} ${data?.lastname}</b>\nលេខទូរសព្ទ៖ <b>${data?.phone_number}</b>`;
    sendMessageFromBot(msg, topicID);
  }

  return (
    <div className='flex items-center justify-center h-full select-none pt-24'>
      <div
        style={{
          position: 'relative',
          background: 'white',
          padding: '40px',
          paddingRight: '200px',
          borderRadius: '24px',
          boxShadow: '0px 1px 15px 2px rgba(0, 0, 0, 0.15)',
          overflow: 'hidden',
        }}>
        <div>
          <div style={{ paddingBottom: '15px' }}>
            <h1 style={{ fontSize: '36px', color: '#009BC8', marginBottom: 0 }}>{t("Sorry!")}</h1>
            <h1 style={{ fontSize: '36px', color: '#009BC8', marginBottom: 0 }}>{t("Your Subscription has been expired.")}</h1>
          </div>
          <p style={{ fontSize: '16px', color: '#68737A', fontWeight: '300' }}>{t("Please continue your subscription to access all the teaching resources and materials.")}</p>
          <div style={{ paddingTop: '60px', paddingBottom: '120px' }}>
            <Button
              type="primary"
              style={{
                border: '0px solid #009BC8',
                backgroundColor: 'rgba(0, 155, 200, 0.15)',
                padding: '0px 20px',
                zIndex: 1,
                color: '#009BC8'
              }}
              shape="round"
              size="large"
              onClick={
                handleSubmit(onRequestSubscribe)
              }
            >
              <div className="flex flex-row space-x-2 items-center">
                <p className="mb-0 font-light">{t('Continue Subscription')}</p>
                <Iconly name='ArrowRight' set='two-tone' primaryColor='#009BC8' size={18} />
              </div>
            </Button>
          </div>
        </div>
        <img
          src='../assets/showcase-content.png'
          style={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            width: '650px'
          }}
        />
      </div>
      <div
        style={{
          background: 'white',
          padding: '20px',
          borderRadius: '0 24px 24px 0',
          overflow: 'hidden',
          width: '350px'
        }}>
        <div style={{ paddingBottom: '5px' }}>
          <h1 style={{ fontSize: '22px', color: '#68737A', marginBottom: 0 }}>{t("We are sorry that you did not continue your subscription.")}</h1>
        </div>
        <p style={{ fontSize: '14px', color: '#68737A', fontWeight: '300' }}>{t("And to help the team meet your needs and other teachers, please write your reasons or comments here. Thank you in advance 🙏")}</p>

        <div className="flex flex-row space-x-1">
          <p style={{ fontSize: '14px', color: '#68737A', fontWeight: '300' }}>{t("Reason or comment")}</p>
          <p style={{ fontSize: '14px', color: 'red', fontWeight: '300' }}>*</p>
        </div>
        <Controller
          name="text"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextArea
                style={{ padding: 10, borderRadius: 12, fontSize: '14px' }}
                value={value}
                onChange={onChange}
                placeholder={t('Write your reason or comment here ...')}
                autoSize={{
                  minRows: 3,
                  maxRows: 3,
                }}
              />
            );
          }}
        />

        <div className='w-full flex justify-end pt-4'>
          <Button
            type="primary"
            style={{
              border: '1px solid #009BC8',
              backgroundColor: 'transparent',
              padding: '0px 20px',
              color: '#009BC8'
            }}
            shape="round"
            size="large"
            onClick={
              handleSubmit(onSubmit)
            }
          >
            <p className="mb-0 font-light">{t('Submit')}</p>
          </Button>
        </div>
      </div>
    </div >
  );
};

export default SubscriptionExpiredPage;
