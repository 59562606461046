import styled from 'styled-components';
export const Center = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;

export const SpacerX = styled.div`
  margin: 10px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: end;
`;
export const ChartHeader = styled.div`
  min-height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
