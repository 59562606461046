import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { uid } from "uid";
import { ROUTE_PATH } from "../constants/path.route";
import { useAuthentication } from "../hooks/useAuth";
import AsurRaaProMasterLayout from "../layouts/master.layout";
import LoginPage from "../pages/login/login.page";
import RegisterPage from "../pages/register/register.page";
import SubscriptionExpiredPage from "../pages/subscription/subscription-expired.page";

import { useGetRouteView } from "../router/router.view";
import { useUserInfoService } from "../services/user.service";
import ResetPasswordPage from "../pages/reset-password/reset-password.page";

const App = () => {
  const { token, setRoleByToken } = useAuthentication();
  const { data } = useUserInfoService();
  const { layoutRouteView, layoutRouteList } = useGetRouteView();

  useEffect(() => {
    setRoleByToken();
  }, [setRoleByToken, data]);

  if (
    token &&
    data?.current_membership &&
    Object.keys(data.current_membership).length === 0
  ) {
    return (
      <AsurRaaProMasterLayout>
        <SubscriptionExpiredPage />
      </AsurRaaProMasterLayout>
    );
  }

  if (token) {
    return (
      <Routes>
        <Route
          element={
            <AsurRaaProMasterLayout>{layoutRouteView}</AsurRaaProMasterLayout>
          }
          path=""
        >
          {[...layoutRouteList]}
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<LoginPage />} path={ROUTE_PATH.LOGIN} key={uid()} />
      <Route
        element={<RegisterPage />}
        path={ROUTE_PATH.REGISTER}
        key={uid()}
      />
      <Route
        element={<ResetPasswordPage />}
        path={ROUTE_PATH.RESET_PASSWORD}
        key={uid()}
      />

      <Route
        path={ROUTE_PATH.NOT_EXIST}
        element={<Navigate to={ROUTE_PATH.LOGIN} replace={true} />}
        key="REDIRECT"
      />
    </Routes>
  );
};
export default App;
