import { useSuraFetcherFactory } from '@asurraa/sura-ui-fetcher';
import { useQuery } from 'react-query';
import { AxiosHttp } from '../config/axios.config';
import { URI_PATH } from '../constants/uri-path.constant';
import { GeneratorApi } from '../types/base-dto.interface';

export type TAdminSetting = GeneratorApi['CreateAdminLoginDto'];
export type AdminResponseDtoType = GeneratorApi['AdminResponseDto'];
export type AdminUpdateResponseDto = GeneratorApi['UpdateAdminLoginDto'];
export type TReturnAdminChangePassword = GeneratorApi['ChangePasswordDto'];
export type TResponseAdminRole = GeneratorApi['AdminRoleResponseDto'];
export type TChangeActivationStatus = GeneratorApi['IdDto'];

export const useGetAdminServices = () => {
  const { data: fetchingData, ...rest } = useQuery<{
    data: AdminResponseDtoType;
  }>(URI_PATH.GET_USER_INFO);
  const data = fetchingData?.data;
  return {
    data,
    ...rest,
  };
};
export const useAdminService = () => {
  return useSuraFetcherFactory<TAdminSetting, AdminResponseDtoType>({
    CREATE: URI_PATH.CREATE_ADMIN,
    GET_ALL: URI_PATH.GET_ALL_ADMIN,
    UPDATE: URI_PATH.UPDATE_ADMIN,
  });
};

export const updateAdminPasswordServices = (
  data: TReturnAdminChangePassword
) => {
  return AxiosHttp.post(URI_PATH.UPDATE_ADMIN_PASSWORD, data);
};

export const updateAdminInfoServices = (data: AdminUpdateResponseDto) => {
  return AxiosHttp.put(URI_PATH.UPDATE_ADMIN_OWN_INFO, data);
};

export const userInfoServices = () => {
  return AxiosHttp.get<AdminResponseDtoType>(URI_PATH.GET_USER_INFO);
};
