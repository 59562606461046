export enum ROUTE_PATH {
  LOGIN = "/login",
  REGISTER = "/register",
  RESET_PASSWORD = "/reset-password",
  NOT_EXIST = "/*",
  DASHBOARD = "/dashboard",
  SETTING = "/setting/:key",
  USER_SETTING = "/user-setting",
  CONFIGURATION = "/config",
  // HOME = "/home",
  CONTENT = "/content",
  TEST_PAGE = "/test-page",
  CATEGORY = "/category",
  PLAY = "/play",
  LIBRARY = "/library/:key",
  LIBRARY_ONE = "/library",
  CATEGORY_DETAIL = "/category/:id",
  VIDEO_DETAIL = "/video/:id",
  VIDEO = "/video",
  WORKSHEET_DETAIL = "/worksheet-detail/:id",
  SMART_LIBRARY = "/smart-library/:subjectId",
  VIDEO_CLICK = "/video_click",
  SUBSCRIPTION_EXPIRED = "/subscription-expired",
  CONTACT_US = "/contact-us",
}
