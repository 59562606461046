import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_PATH } from "../constants/path.route";
import { useGetRouteList } from "./route-list";

export const useGetRouteView = (): {
  layoutRouteView: JSX.Element;
  layoutRouteList: JSX.Element[];
} => {
  const { routeList } = useGetRouteList();

  const routeComponents = routeList?.map(({ path, element }, index) => {
    const RouteElement = element;

    return <Route path={path} element={<RouteElement />} key={index} />;
  });

  const redirect = (
    <Route
      path={ROUTE_PATH.NOT_EXIST}
      element={<Navigate to={ROUTE_PATH.SMART_LIBRARY} replace={true} />}
      key="REDIRECT"
    />
  );

  const layoutRouteList = [...routeComponents, redirect];

  return {
    layoutRouteView: <Routes>{[...routeComponents, redirect]}</Routes>,
    layoutRouteList: layoutRouteList,
  };
};
