import { FC } from 'react';
import animationData from '../../assets/animations/loading.json';

import Img from 'react-cool-img';
import Lottie, { Options } from 'react-lottie';
import chlatLogo from '../../assets/main-logo.png';

export const LoadingAnimate: FC = (props) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div
      style={{ minHeight: '80vh' }}
      className="flex  items-center justify-center"
    >
      <div>
        <div style={{ width: 80, height: 50 }}>
          <div style={{ width: 100, height: 80 }}>
            <Img src={chlatLogo} />
          </div>
          <div style={{ marginLeft: -150, marginTop: -168 }}>
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        </div>
      </div>
    </div>
  );
};
