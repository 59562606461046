import { Button, Input, Modal, Radio, Select, message } from "antd";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";
import { FaBriefcase } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuraMultiCountryPhoneInput } from "@asurraa/multi-country-phone-input";
import { useWebPortalRegisterUserService } from "apps/web-portal/src/services/web-portal-register-user.service";
import { ResponseMessage } from "apps/dashboard/src/components/sura-message/response-message";
import { PrimaryColor } from "apps/web-portal/src/constants/theme.constant";
import { userLoginWithUsernameAndPassword } from "apps/web-portal/src/services/auth.service";
import { useAuthentication } from "apps/web-portal/src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "apps/web-portal/src/constants/path.route";

export default function RegisterComponent() {
  const { Option } = Select;

  const { saveUser } = useAuthentication();
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("remember_me") || "false"
  );
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ username: "", password: "" });
  const { createOne } = useWebPortalRegisterUserService();
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const lastName = watch("lastname");
  const firstName = watch("firstname");
  const { t } = useTranslation();
  const password = watch("password");

  useEffect(() => {
    if (lastName && firstName) {
      const generatedUsername = lastName + firstName;
      setValue("username", generatedUsername);
    }
  }, [lastName, firstName, setValue]);

  const onSubmit = (data: FieldValues) => {
    console.log("data submitted", data);

    createOne({ data })
      .then(() => {
        ResponseMessage.success();
        reset();
        setLoginInfo({
          username: data["username"],
          password: data["password"],
        });
        setIsModalOpen(true);
      })
      .catch((err) => {
        // console.log("Error:", err);
        if (err.response && err.response.data) {
          const errorMessage = err.response.data.message;
          message.error(errorMessage);
        } else {
          message.error("An unknown error occurred.");
        }
      });
  };

  const handleContinueClick = () => {
    userLoginWithUsernameAndPassword({
      username: loginInfo.username,
      password: loginInfo.password,
    })
      .then((res) => {
        const { token } = res.data;
        const { refresh_token } = res.data;
        saveUser({ token: token, refresh_token: refresh_token });
        localStorage.setItem("remember_me", rememberMe);
        navigate(ROUTE_PATH.SMART_LIBRARY);
      })
      .catch((loginError) => {
        message.error("Login failed: " + loginError.message);
      });
  };

  const loginInfoModal = () => {
    return (
      <Modal
        title={t("Your Account Information")}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            onClick={handleContinueClick}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              height: 35,
              background: PrimaryColor,
              color: "white",
            }}
            key="next"
          >
            {t("Continue")}
          </Button>,
        ]}
      >
        <div className=" mt-2 flex flex-col items-center justify-center p-6">
          <p className=" text-lg">
            {t("Your username:")}{" "}
            <span className=" font-mono font-semibold">
              {loginInfo.username}
            </span>
          </p>
          <p className=" text-lg">
            {t("Your password:")}{" "}
            <span className="font-mono font-semibold">
              {loginInfo.password}
            </span>
          </p>
        </div>
        <div className="-mb-5 mt-4">
          <p className=" text-xs font-light">
            {t("*Note: You may take a picture of this information.")}
          </p>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-[35vw] flex-col gap-3 "
      >
        <div className="flex gap-[10px]">
          <Controller
            name="lastname"
            rules={{ required: true }}
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  style={{ padding: 10, borderRadius: 12, height: 40 }}
                  placeholder={t("Lastname")}
                  autoComplete="off"
                />
              );
            }}
          />

          <Controller
            name="firstname"
            rules={{ required: true }}
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  style={{ padding: 10, borderRadius: 12, height: 40 }}
                  placeholder={t("Firstname")}
                  autoComplete="off"
                />
              );
            }}
          />
        </div>
        <Controller
          name="username"
          rules={{ required: true }}
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ padding: 10, borderRadius: 12, height: 40 }}
                placeholder={t("Username")}
                name="username"
                autoComplete="off"
              />
            );
          }}
        />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field }) => {
            return (
              <Input.Password
                {...field}
                placeholder={t("Password")}
                style={{ padding: 10, borderRadius: 12 }}
                autoComplete="off"
              />
            );
          }}
        />
        <Controller
          name="confirm_password"
          rules={{
            validate: (value) => value === password,
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Input.Password
                {...field}
                placeholder={t("Confirm Password")}
                style={{ padding: 10, borderRadius: 12 }}
                autoComplete="off"
              />
              {error && (
                <p style={{ color: "red" }}>
                  {t("The password does not match")}
                </p>
              )}
            </div>
          )}
        />

        <Controller
          name="phone_number"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <SuraMultiCountryPhoneInput
                {...field}
                type="number"
                style={{
                  padding: 10,
                  borderRadius: 12,
                  height: 40,
                  fontSize: 12.5,
                }}
                size="large"
                placeholder={t("Phone Number")}
              />
            );
          }}
        />
        <div className="flex justify-between gap-2 sm:gap-6 ">
          <Controller
            name="age"
            rules={{ required: true }}
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  type="number"
                  style={{
                    padding: 10,
                    borderRadius: 12,
                    height: 40,
                  }}
                  placeholder={t("Age")}
                  autoComplete="off"
                  onChange={(e) => {
                    const numberValue =
                      e.target.value === "" ? "" : Number(e.target.value);
                    field.onChange(numberValue);
                  }}
                />
              );
            }}
          />
          <Controller
            name="gender"
            rules={{ required: true }}
            control={control}
            render={({ field }) => {
              return (
                <Radio.Group {...field}>
                  <div className=" w-full sm:mt-2 sm:flex sm:gap-2 ">
                    <Radio value="male">{t("Male")}</Radio>
                    <Radio value="female">{t("Female")}</Radio>
                  </div>
                </Radio.Group>
              );
            }}
          />
        </div>

        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <RadioGroup className="flex gap-2" {...field}>
                <RadioGroup.Option
                  value="TEACHER"
                  className="w-full rounded-2xl bg-primaryColor/5 "
                >
                  {({ checked }) => (
                    <div
                      className={`text-md block w-full cursor-pointer select-none rounded-lg p-4 text-center font-light ${
                        checked
                          ? "bg-primary text-primaryColor"
                          : "text-secondary text-primaryColor/50"
                      }`}
                    >
                      <div className="flex flex-col items-center justify-start space-y-2">
                        <FaBriefcase className="stroke-primaryColor h-6 w-6" />
                        <p>{t("Teacher")}</p>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option
                  value="PARENT"
                  className="w-full rounded-2xl bg-primaryColor/5 "
                >
                  {({ checked }) => (
                    <div
                      className={`text-md block w-full cursor-pointer select-none rounded-lg p-4 text-center font-light ${
                        checked
                          ? "bg-primary text-primaryColor"
                          : "text-secondary text-primaryColor/50"
                      }`}
                    >
                      <div className="flex flex-col items-center justify-start space-y-2">
                        <BsPeopleFill className="stroke-primaryColor h-6 w-6" />
                        <p>{t("Parents")}</p>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            );
          }}
        />

        <Controller
          name="school_name"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ padding: 10, borderRadius: 12, height: 40 }}
                placeholder={t("School Name")}
                name="school_name"
                autoComplete="off"
              />
            );
          }}
        />

        <Controller
          name="district"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ padding: 10, borderRadius: 12, height: 40 }}
                placeholder={t("District")}
                name="district"
                autoComplete="off"
              />
            );
          }}
        />

        <Controller
          name="province"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ padding: 10, borderRadius: 12, height: 40 }}
                placeholder={t("Province")}
                name="province"
                autoComplete="off"
              />
            );
          }}
        />

        <Controller
          name="reason"
          control={control}
          render={({ field }) => {
            return (
              <Input.TextArea
                {...field}
                showCount
                maxLength={250}
                placeholder={t("Reasons why you are using KruChlat")}
                style={{ height: 80 }}
              />
            );
          }}
        />

        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: "100%",
            height: 40,
            borderRadius: 12,
            marginTop: 20,
          }}
        >
          {t("Register")}
        </Button>
      </form>
      {loginInfoModal()}
    </div>
  );
}
