import {
  API_BASE_URL,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
} from '../constants/app.constant';
import axios, { Axios } from 'axios';
import { AxiosHttp } from '../config/axios.config';
import { AuthResponse, HttpResponse, LogOutResponse } from '../types/common';
import { URI_PATH } from '../constants/uri-path.constant';

export interface LoginProps {
  username: string;
  password: string;
}

export const LoginUser = async (
  info: LoginProps
): Promise<HttpResponse<AuthResponse>> => {
  try {
    const res = await AxiosHttp.post(URI_PATH.ADMIN_LOGIN, info);
    return { data: res?.data };
  } catch (ex) {
    return { error: ex };
  }
};

export const logOut = async () => {
  return await AxiosHttp.post(URI_PATH.LOG_OUT);
};

export const refreshAdminToken = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  const res = await axios.post(
    `${API_BASE_URL}/administrator/refresh-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );
  const newToken = res?.data?.token;
  localStorage.setItem(TOKEN_KEY, newToken);
};

export interface AuthLoginWithPhoneInterface {
  phone_number: string;
}

type UserAuthResponse = {
  token: string;
  refresh_token: string;
};

export const userLoginWithPhoneService = async (
  value: AuthLoginWithPhoneInterface
) => {
  return AxiosHttp.post<UserAuthResponse>(
    URI_PATH.USER_SIGNIN_WITH_PHONE,
    value
  );
};

export const userLoginWithUsernameAndPassword = (value: {
  username: string;
  password: string;
}) => {
  return AxiosHttp.post<UserAuthResponse>(
    URI_PATH.USER_SIGNIN_WITH_USERNAME_PASSWORD,
    value
  );
};
