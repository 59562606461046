import { lazy, LazyExoticComponent } from "react";
import type { RouteProps } from "react-router-dom";
import { ROUTE_PATH } from "../constants/path.route";
const LibraryPage = lazy(() => import("../pages/library/library.page"));
const CategoryPage = lazy(() => import("../pages/category/category-page"));
const PlayPage = lazy(() => import("../pages/play/play.page"));
const IndexSettingPage = lazy(
  () => import("../pages/setting/index-setting.page")
);
const CategoryDetailPage = lazy(
  () => import("../pages/category/category-detail.page")
);
const VideoPlayDetail = lazy(
  () => import("../pages/video-play-detail/video-play-detail.page")
);
const SmartLibrary = lazy(
  () => import("../pages/smart-library/smart-library.page")
);
const WorksheetDetailPage = lazy(
  () => import("../pages/worksheet/worksheet-detail")
);
const ContactUsPage = lazy(() => import("../pages/contact-us/contact-us.page"));

interface RouteListProps extends Omit<RouteProps, "element"> {
  element: LazyExoticComponent<(props: any) => JSX.Element>;
}

export const useGetRouteList = () => {
  const routeList: RouteListProps[] = [
    // { path: ROUTE_PATH.HOME, element: HomePage,},
    { path: ROUTE_PATH.SETTING, element: IndexSettingPage },
    { path: ROUTE_PATH.WORKSHEET_DETAIL, element: WorksheetDetailPage },
    { path: ROUTE_PATH.PLAY, element: PlayPage },
    { path: ROUTE_PATH.CATEGORY, element: CategoryPage },
    { path: ROUTE_PATH.LIBRARY, element: LibraryPage },
    { path: ROUTE_PATH.CATEGORY_DETAIL, element: CategoryDetailPage },
    { path: ROUTE_PATH.VIDEO_DETAIL, element: VideoPlayDetail },
    { path: ROUTE_PATH.SMART_LIBRARY, element: SmartLibrary },
    { path: ROUTE_PATH.LIBRARY_ONE, element: LibraryPage },
    { path: ROUTE_PATH.CONTACT_US, element: ContactUsPage },
  ];
  return { routeList };
};
