import { Input, Button, Radio, Divider } from "antd";
import logo from "../../assets/main-logo.png";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n, { setLangToLocalStorage } from "../../locales/i18n.main";
import { Center } from "../../components/style-component/layout.style-component";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/path.route";
import ResetPasswordComponent from "./components/reset-password-component";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const RegisterLayout = (
    <div style={{ backgroundColor: "#FFFFFF" }} className="h-full w-full">
      <Center className="">
        <div>
          <div
            style={{ backgroundColor: "#FFFFFFF" }}
            className="rounded-xl px-12 py-5"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                style={{ paddingBottom: 20 }}
                className="flex flex-col space-y-1"
              >
                <img src={logo} width={120} alt={"logo"} />
                <Divider></Divider>
                <p className="text-[14px] text-primaryColor">
                  {t("Created by teacher for teacher")}
                </p>
              </div>
            </div>

            <ResetPasswordComponent />

            <div className="flex justify-center  ">
              <div style={{ width: "100%" }}></div>
            </div>
            <Divider dashed></Divider>
            <div className="flex justify-center ">
              <div style={{ width: "100%" }}>
                <Button
                  onClick={() => openInNewTab("https://t.me/danychhe")}
                  style={{ width: "100%", height: 40, borderRadius: 12 }}
                  type="ghost"
                  htmlType="submit"
                  block
                  className="login-form-button "
                >
                  {t("Contact Kru Chlat Team")}
                </Button>
              </div>
            </div>
          </div>

          <div className="mt-auto flex justify-center space-x-1">
            <p style={{ marginBottom: 5, fontSize: 12 }}>
              {t("Already have an account?")}
            </p>
            <p
              onClick={() => navigate(ROUTE_PATH.LOGIN)}
              className="cursor-pointer select-none underline"
              style={{ fontSize: 12, color: "#1199E9" }}
            >
              {t("Click here")}
            </p>
          </div>

          <div className="mt-auto mb-4 flex justify-center">
            <Radio.Group
              onChange={(e) => {
                const lang = e.target.value;
                setLangToLocalStorage(lang);
                i18n.changeLanguage(lang);
              }}
              defaultValue={"km"}
            >
              <Radio value={"km"}>🇰🇭 Khmer</Radio>
              <Radio value={"en"}>🇺🇸 English</Radio>
            </Radio.Group>
          </div>
        </div>
      </Center>
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      {RegisterLayout}
      <div id={"recaptcha-container"}></div>
    </div>
  );
};

export default ResetPasswordPage;
