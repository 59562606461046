import { message } from 'antd';
import { ErrorResponseInterface } from '../../types/common';
import { ErrorInterface } from '../../types/response.interface';

export class ResponseMessage {
  static success(text?: string | undefined) {
    message.success(text || 'Success');
  }

  static error(text: ErrorInterface | ErrorResponseInterface) {
    message.error(text?.message);
  }
}
