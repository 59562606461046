import { useSuraFetcherFactory } from "@asurraa/sura-ui-fetcher";
import { GeneratorApi } from "../types/base-dto.interface";
import { URI_PATH } from "../constants/uri-path.constant";

export type WebPortalRegisterUser =
  GeneratorApi["CreateWebPortalRegisterUserDto"];
export type WebPortalRegisterUserDto =
  GeneratorApi["WebPortalUserRegisterResponseDto"];

export const useWebPortalRegisterUserService = () => {
  return useSuraFetcherFactory<WebPortalRegisterUser, WebPortalRegisterUserDto>(
    {
      //GET_ALL not working yet only create
      GET_ALL: URI_PATH.GET_ALL_USER,
      CREATE: URI_PATH.REGISTER_USER,
    }
  );
};
