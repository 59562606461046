import create from 'zustand';
import { TConfiguration } from '../services/app-setting.service';

type ConfigState = {
  stateData: {
    lang: string;
  };
  initDeveloperConfig: () => void;
};

export const useStoreDeveloperConfig = create<ConfigState>((set) => ({
  stateData: {
    lang: 'en, kh',
  },

  initDeveloperConfig: async () => {
    set({
      stateData: {
        lang: 'en',
      },
    });
  },
}));

export interface ConfigurationStoreState {
  configData: TConfiguration[];
  setConfigData: (data: TConfiguration[]) => void;
  getConfigByKey: (configKey: TConfiguration['key']) => string | undefined;
}

export const useConfigurationStore = create<ConfigurationStoreState>(
  (set, get) => ({
    configData: [],
    setConfigData(data) {
      set({ configData: data });
    },
    getConfigByKey(configKey) {
      const configValue = get().configData.find(
        (config) => config.key === configKey
      )?.value;
      return configValue;
    },
  })
);
