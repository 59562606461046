import {
  CaretDownOutlined,
  DownloadOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import ProLayout from "@ant-design/pro-layout";
import { MenuItemsProps } from "@asurraa/sura-ui-pico-krubkrong-layout";
import { Avatar, Button, Divider, Dropdown, Menu, message } from "antd";
import React, { Fragment, ReactNode, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { uid } from "uid";
import chlat from "../assets/main-logo.png";
import { AppLoading } from "../components/app-loading/app-loading";
import { LoadingAnimate } from "../components/loading-animate/global-loading.animate.page";
import { getImageFullPath } from "../constants/app.constant";
import { ROUTE_PATH } from "../constants/path.route";
import { useAuthentication } from "../hooks/useAuth";
import { langKeyArray, setLangToLocalStorage } from "../locales/i18n.main";
import { useGetAdminServices } from "../services/admin.service";
import { useStoreDeveloperConfig } from "../zustands/config.zustand";
import { useGlobalLoading } from "../zustands/global-loading.zustand";
import { logOut } from "../services/auth.service";
import { Iconly } from "react-iconly";
import { IoLanguageOutline } from "react-icons/io5";

const AsurRaaProMasterLayout: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const route = useNavigate();
  const { globalLoading } = useGlobalLoading();
  const { t, i18n } = useTranslation();
  const {
    stateData: { lang },
  } = useStoreDeveloperConfig();
  const location = useLocation();
  const { logoutUser } = useAuthentication();
  const { data: adminData } = useGetAdminServices();

  const navigationMenuComponent = () => {
    const navigationMenu: MenuItemsProps[] = [
      // {
      //   label: t("smart-library"),
      //   key: uid(),
      //   icon: <img src="assets/chlatLibrary.svg" width={12} />,
      //   onClick: () => {
      //     route(ROUTE_PATH.SMART_LIBRARY);
      //   },
      // },
      {
        label: t("Logout"),
        key: uid(),
        icon: <LoginOutlined />,
        onClick: () => {
          logOut()
            .then(() => {
              logoutUser();
              message.success(t("You have successfully logged out!"));
            })
            .catch((e) => {
              message.error(t("Log out failed!"));
            });
        },
      },
    ];
    return <Menu items={navigationMenu} />;
  };
  const languageMenuComponent = () => {
    const menuItemsLang: MenuItemsProps[] = langKeyArray(lang)?.map(
      (lang, index) => {
        return {
          label: t(lang.message),
          key: index,
          onClick: () => {
            i18n.changeLanguage(lang.key);
            message.info(`Change to ${t(lang.message)}`);
            setLangToLocalStorage(lang.key);
          },
        };
      }
    );
    return <Menu items={menuItemsLang} />;
  };

  return (
    <div style={{ height: "100vh" }}>
      {globalLoading === true ? (
        <div className="flex min-h-screen items-center justify-center align-middle">
          <div>
            <LoadingAnimate />
          </div>
        </div>
      ) : (
        <Fragment>
          <ProLayout
            fixedHeader
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={[ROUTE_PATH.SMART_LIBRARY]}
            fixSiderbar
            title=""
            navTheme={"light"}
            layout="top"
            contentWidth="Fluid"
            logo={
              <div
                onClick={() => {
                  route(ROUTE_PATH.SMART_LIBRARY);
                }}
                className="mt-0 ml-6 flex flex-row items-center space-x-1"
              >
                <img src={chlat} alt="Kru Chlat" height={40} />
                <Divider type="vertical" style={{ height: "25px" }}></Divider>
                <div className="select-none text-[14px] text-primaryColor">
                  {t("Created by teacher for teacher")}
                </div>
              </div>
            }
            siderWidth={200}
            className="umi-plugin-layout-main"
            rightContentRender={() => (
              <Fragment>
                <div
                  className="mr-6"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div className="flex select-none flex-row items-center space-x-4">
                    <div
                      onClick={() => {
                        route(ROUTE_PATH.CONTACT_US);
                      }}
                      className="flex flex-row items-center space-x-2 hover:cursor-pointer hover:text-primaryColor"
                    >
                      <p className="mb-0">{t("Contact Us")}</p>
                      <Iconly
                        name="People"
                        set="bulk"
                        primaryColor="currentColor"
                        size={18}
                      />
                    </div>

                    <Dropdown
                      className="cursor-pointer"
                      overlay={languageMenuComponent()}
                    >
                      <div className="flex items-center justify-center gap-1">
                        <IoLanguageOutline />
                        <span className="hover:text-primaryColor">
                          {t("Language")}
                        </span>
                      </div>
                    </Dropdown>

                    <div className="flex select-none flex-row items-center space-x-2 pb-0">
                      <p className="mb-0">
                        {adminData?.firstname + " " + adminData?.lastname}
                      </p>
                      <Iconly
                        name="ChevronDown"
                        set="two-tone"
                        primaryColor="gray"
                        size={18}
                      />
                    </div>
                    <Divider type="vertical" style={{ height: "25px" }} />
                    <Button
                      type="primary"
                      style={{
                        border: "0px solid red",
                        backgroundColor: "rgba(255, 0, 0, 0.15)",
                        padding: "0px 20px",
                        color: "red",
                      }}
                      shape="round"
                      size="large"
                      onClick={() => {
                        logOut()
                          .then(() => {
                            logoutUser();
                            message.success(
                              t("You have successfully logged out!")
                            );
                          })
                          .catch((e) => {
                            message.error(t("Log out failed!"));
                          });
                      }}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <p className="mb-0">{t("Logout")}</p>
                        <Iconly
                          name="ArrowRight"
                          set="two-tone"
                          primaryColor="red"
                          size={18}
                        />
                      </div>
                    </Button>
                  </div>
                </div>
              </Fragment>
            )}
          >
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              }}
            >
              <Suspense
                fallback={
                  <div>
                    <AppLoading />
                  </div>
                }
              >
                {children}
              </Suspense>
            </div>
          </ProLayout>
        </Fragment>
      )}
    </div>
  );
};

export default AsurRaaProMasterLayout;
