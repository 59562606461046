import { Button, Input, Modal, message } from "antd";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";
import { FaBriefcase } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SuraMultiCountryPhoneInput } from "@asurraa/multi-country-phone-input";
import { useWebPortalRegisterUserService } from "apps/web-portal/src/services/web-portal-register-user.service";
import { ResponseMessage } from "apps/dashboard/src/components/sura-message/response-message";
import { PrimaryColor } from "apps/web-portal/src/constants/theme.constant";
import { userLoginWithUsernameAndPassword } from "apps/web-portal/src/services/auth.service";
import { useAuthentication } from "apps/web-portal/src/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "apps/web-portal/src/constants/path.route";

export default function ResetPasswordComponent() {
  const { control, handleSubmit, watch, reset } = useForm();
  const password = watch("password");
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (data: FieldValues) => {
    console.log("data for reset password", data);
    setIsModalOpen(true);
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    reset();
  };

  const passwordResettingModal = () => {
    return (
      <Modal
        title={t("Setting New Password")}
        open={isModalOpen}
        onCancel={onCancelClick}
        footer={[
          <Button
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              height: 35,
              background: PrimaryColor,
              color: "white",
            }}
            key="ok"
          >
            {t("Ok")}
          </Button>,
        ]}
      >
        <form className="flex flex-col gap-5">
          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  placeholder={t("New Password")}
                  style={{ padding: 10, borderRadius: 12 }}
                  autoComplete="off"
                />
              );
            }}
          />
          <Controller
            name="confirm_password"
            rules={{
              validate: (value) => value === password,
            }}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <Input.Password
                  {...field}
                  placeholder={t("Confirm New Password")}
                  style={{ padding: 10, borderRadius: 12 }}
                  autoComplete="off"
                />
                {error && (
                  <p style={{ color: "red" }}>
                    {t("The password does not match")}
                  </p>
                )}
              </div>
            )}
          />
        </form>
      </Modal>
    );
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-[35vw] flex-col gap-3 "
      >
        <h1 className=" text-lg">{t("Reset Password")}</h1>

        <Controller
          name="username"
          rules={{ required: true }}
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                style={{ padding: 10, borderRadius: 12, height: 40 }}
                placeholder={t("Username")}
                name="username"
                autoComplete="off"
              />
            );
          }}
        />

        <Controller
          name="phone_number"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <SuraMultiCountryPhoneInput
                {...field}
                type="number"
                style={{
                  padding: 10,
                  borderRadius: 12,
                  height: 40,
                  fontSize: 12.5,
                }}
                size="large"
                placeholder={t("Phone Number")}
              />
            );
          }}
        />

        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: "100%",
            height: 40,
            borderRadius: 12,
            marginTop: 20,
          }}
        >
          {t("Continue")}
        </Button>
      </form>
      {passwordResettingModal()}
    </div>
  );
}
