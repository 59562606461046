import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Flexbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppLoading = () => {
  const AppLoading = (
    <div>
      <Flexbox style={{ height: '100vh' }}>
        {/* <LoadingOutlined size={100} /> */}
      </Flexbox>
    </div>
  );
  return AppLoading;
};
