import axios from "axios";
import { CHAT_ID, TELEGRAM_BOT_API } from "../constants/app.constant";

export const sendMessageToGroup = async (text, topicID) => {
    return await axios.post(
        TELEGRAM_BOT_API,
        { 
            chat_id: CHAT_ID,
            text,
            reply_to_message_id: topicID,
            parse_mode: "HTML"
         },
    );
};