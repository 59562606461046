import create from 'zustand';

export interface GlobalLoadingState {
  globalLoading: boolean;
  setGlobalLoading: (loading: boolean) => void;
}

export const useGlobalLoading = create<GlobalLoadingState>((set) => ({
  globalLoading: false,
  setGlobalLoading: (loading: boolean) =>
    set((state) => ({ globalLoading: loading })),
}));
