import { useSuraFetcherFactory } from "@asurraa/sura-ui-fetcher";
import { useQuery } from "react-query";
import { AxiosHttp } from "../config/axios.config";
import { URI_PATH } from "../constants/uri-path.constant";
import { GeneratorApi } from "../types/base-dto.interface";

export type TCreateUser = GeneratorApi["CreateUserDto"];
export type TUserResponse = GeneratorApi["UserResponse"];
export type TChangeActivationStatus = GeneratorApi["IdDto"];
export type TUserChangePassword = GeneratorApi["UserChangePasswordDto"];
export type TUserChangePasswordByAdmin =
  GeneratorApi["ChangePasswordByAdminDto"];

export const useUserService = () => {
  return useSuraFetcherFactory<TCreateUser, TUserResponse>({
    CREATE: URI_PATH.CREATE_USER,
    GET_ALL: URI_PATH.GET_ALL_USER,
    UPDATE: URI_PATH.UPDATE_ONE_USER,
    GET_ONE: URI_PATH.GET_ONE_USER,
    DELETE: URI_PATH.DELETE_ONE_USER,
  });
};

export const updateUserActivationService = (data: TChangeActivationStatus) => {
  return AxiosHttp.post(URI_PATH.USER_CHANGE_STATUS, data);
};

export const userChangePasswordService = (data: TUserChangePassword) => {
  return AxiosHttp.post(URI_PATH.USER_CHANGE_PASSWORD, data);
};

export const userChangePasswordByAdminService = (
  id: string | undefined,
  data: TUserChangePasswordByAdmin
) => {
  return AxiosHttp.patch(
    `${URI_PATH.USER_CHANGE_PASSWORD_BY_ADMIN}/${id}`,
    data
  );
};

export type ResponseUserInfo = GeneratorApi["UserResponse"];
export const useUserInfoService = () => {
  const { data: fetchingData, ...rest } = useQuery<{
    data: ResponseUserInfo;
  }>(URI_PATH.GET_USER_INFO);
  const data = fetchingData?.data;
  return {
    data,
    ...rest,
  };
};
